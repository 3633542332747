import React from 'react'
import { Table } from 'react-super-responsive-table';


function HistoriaFamiliar() {
  return (
    <div className='historia-clinica'>

<div  className=''>

    
<div  className='subtitulo'>
      <h3>Historia Familiar</h3>
   </div>



        <Table >
            <thead>
            <tr >
                   <th className='color-table'> </th>
                    <th className='color-table'><a type='button' >Añadir      <i className="bi bi-plus"></i></a></th>
                    <th className='color-table'> <a type='button'>Editar      <i className="bi bi-pencil-square"></i></a></th>
                    <th className='color-table'><a type='button'>Actualizar   <i className="bi bi-check-circle"></i></a></th>
                    <th className='color-table'> <a type='button'>Guardar     <i className="bi bi-check-circle"></i></a></th>
                    <th className='color-table'> </th>
   
                </tr>
                <tr >
                    <th className='color-table'> </th>
                    <th className='color-table'> Parentesco</th>
                    <th className='color-table'> Edad</th>
                    <th className='color-table'> Estado de salud</th>
                    <th className='color-table'> Edad a su muerte</th>
                    <th className='color-table'> Causa de muerte</th>
   
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td className='border-td'>
                        X
                    </td>


                    <td className='border-td'>
                  <select>
                       <option>---</option>
                       <option>Padre</option>
                       <option>Madre</option>
                       <option>Hijo</option>
                  </select>
                    </td>


                    <td className='border-td'>
                        32
                    </td>

                    <td className='border-td'>
                 
                  <select>
                       <option>---</option>
                       <option>Sludable</option>
                    
                  </select>
                    </td>

                    <td className='border-td'>
                        -
                    </td>

                    <td className='border-td'>
                        -
                    </td>



                </tr>
            </tbody>
        </Table>

        </div>










        

<div  className='historia-familiar'>
    <div className='titulo'>
        <p >Hábitos (drogras, tabaco, alcohol, etc.):</p>
    </div>



        <Table >
            <thead>

            <tr >
                   <th className='color-table'> </th>
                    <th className='color-table'><a type='button' >Añadir      <i className="bi bi-plus"></i></a></th>
                    <th className='color-table'> <a type='button'>Editar      <i className="bi bi-pencil-square"></i></a></th>
                    <th className='color-table'><a type='button'>Actualizar   <i className="bi bi-check-circle"></i></a></th>
                    <th className='color-table'> <a type='button'>Guardar     <i className="bi bi-check-circle"></i></a></th>
                    
   
                </tr>
                <tr >
                    <th ></th>
                    <th className='color-table'> Tipo de droga</th>
                    <th className='color-table'> Tiempo de consumo</th>
                    <th className='color-table'> Frecuencia</th>
                    <th className='color-table'> Observaciones</th>
  
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td className='border-td'>
                    X
                    </td>


                    <td className='border-td'>
                    Tabac
                    </td>


                    <td className='border-td'>
                    + 5 año
                    </td>

                    <td className='border-td'>
                    De 2 a 3 dias por seman
                    </td>

                    <td className='border-td'>
                    Tuvo problemas en los pulmones
                    </td>

                </tr>
            </tbody>
        </Table>

        </div>










        
<div  className='historia-familiar'>
    <div className='titulo'>
        <p >Hábitos alimenticios y deportes (último año):</p>
    </div>



        <Table >
            <thead>

            <tr >
                   <th className='color-table'> </th>
                    <th className='color-table'><a type='button' >Añadir      <i className="bi bi-plus"></i></a></th>
                    <th className='color-table'> <a type='button'>Editar      <i className="bi bi-pencil-square"></i></a></th>
                    <th className='color-table'><a type='button'>Actualizar   <i className="bi bi-check-circle"></i></a></th>
                    <th className='color-table'> <a type='button'>Guardar     <i className="bi bi-check-circle"></i></a></th>
                    
   
                </tr>
                <tr >
                    <th >  </th>
                    <th className='color-table'> Kg aumentados</th>
                    <th className='color-table'> Kg Disminuidos</th>
                    <th className='color-table'> Frecuencia de actividad física</th>
                    <th className='color-table'> Tipo de actividad física</th>
  
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td className='border-td'>
                        X
                    </td>


                    <td className='border-td'>
                       5
                    </td>


                    <td className='border-td'>
                        0
                    </td>

                    <td className='border-td'>
                        De 2 a 3 dias por semana
                    </td>

                    <td className='border-td'>
                        Atletismo
                    </td>

                </tr>
            </tbody>
        </Table>

        </div>




        <div  >


        <div className='titulo'>
        <p >Antecedentes personales y padecimiento actual:</p>
    </div>


         
    <div className='div-direccion'>
            <div className='relleno salud'>
               
              <p></p>    
              <textarea name="textarea" rows="8" cols="30" placeholder='Describa el o los padecimientos actuales
              del paciente, así como toda la información relevante a ello'></textarea>
            </div>
            <br />
            <div className='relleno  salud'>
           
            <p> </p>
           
     <textarea name="textarea" rows="8" cols="30"  placeholder='Describa el o los padecimientos que ha tenido en el pasado el 
            paciente, así como toda la información relevante a ello'></textarea>
    
           <br />
            <br />
            </div>   
        </div>





        </div>




</div>
  )
}

export default HistoriaFamiliar