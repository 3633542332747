import React from 'react'

function SaludReproductiva() {
  return (
    <div className='data-consulta'>
      <div className='subtitulo'>
        <h3>Salud Reproductiva</h3>
      </div>
      <div className='div-direccion'>
        <div className='relleno salud'>
          <h3>Embarazo</h3>
          <p>Trimestre gestional:</p>
          <select name="select">
            <option value="value1">Seleccione una Opción</option>
          </select>
        </div>
        <br />
        <div className='relleno  salud'>
          <h3>Puerperio</h3>
          <p> Aceptante de PF:</p>
          <select name="select">
            <option value="value1">Seleccione una Opción</option>
          </select>
          <br />
          <br />
        </div>
      </div>
      <div className='div-direccion'>
        <div className='relleno salud'>
          <p>Alto riesgo primera vez:</p>
          <textarea name="textarea" rows="8" cols="30"></textarea>
        </div>
        <br />
        <div className='relleno  salud'>
          <p> Terapia hormonal (Menopausia):</p>
          <textarea name="textarea" rows="8" cols="30"></textarea>
          <br />
          <br />
        </div>
      </div>



      <div className='div-direccion'>
        <div className='relleno salud'>
          <p>Complicaciones:</p>
          <select name="select">
            <option value="value1">Seleccione una Opción</option>
          </select>
        </div>
        <br />
        <div className='relleno  salud'>
          <p> Otras acciones:</p>
          <select name="select">
            <option value="value1">Seleccione una Opción</option>
          </select>
          <br />
          <br />
        </div>
      </div>
    </div>
  )
}

export default SaludReproductiva