import React from 'react'
import { useState } from 'react'
import './historiaclinica.css'
import AntecedentesPatologicos from './AntecedentesPatologicos';
import HistoriaFamiliar from './HistoriaFamiliar';
import HistoriaEstudios from './HistoriaEstudios';
import Pulso from  './Pulso';
import Odontograma from './Odontograma';


function MenuHistoria(props) {
  
  const [isActiveMediciones, setActiveMediciones] = useState(true);
  const [isActiveCobertura, setActiveCobertura] = useState(false);
  const [isActiveSaludR, setActiveSaludR] = useState(false);
  const [isActiveOtrosE, setActiveOtrosE] = useState(false);
  const [isActiveSaludN, setActiveSaludN] = useState(false);
  const [isActivePromocion, setActivePromocion] = useState(false);
  const [isActiveHorarios, setActiveHorarios] = useState(false);
  


  const toggleClassMediciones = () => {
    setActiveMediciones(!isActiveMediciones);
    setActiveCobertura(false);
    setActiveSaludR(false);
    setActiveOtrosE(false);
    setActiveSaludN(false);
    setActivePromocion(false);
    setActiveHorarios(false);
  };
  const toggleClassCobertura = () => {
    setActiveCobertura(!isActiveCobertura);
    setActiveMediciones(false);
    setActiveSaludR(false);
    setActiveOtrosE(false);
    setActiveSaludN(false);
    setActivePromocion(false);
    setActiveHorarios(false);
  };

  const toggleClassSaludR = () => {
    setActiveSaludR(!isActiveSaludR);
    setActiveMediciones(false);
    setActiveCobertura(false);
    setActiveOtrosE(false);
    setActiveSaludN(false);
    setActivePromocion(false);
    setActiveHorarios(false);
  };

  const toggleClassOtrosE = () => {
    setActiveOtrosE(!isActiveOtrosE);
    setActiveMediciones(false);
    setActiveCobertura(false);
    setActiveSaludR(false);
    setActiveSaludN(false);
    setActivePromocion(false);
    setActiveHorarios(false);
  };

  const toggleClassSaludN = () => {
    setActiveSaludN(!isActiveSaludN);
    setActiveMediciones(false);
    setActiveCobertura(false);
    setActiveSaludR(false);
    setActiveOtrosE(false);
    setActivePromocion(false);
    setActiveHorarios(false);
  };

  const toggleClassPromocion= () => {
    setActivePromocion(!isActivePromocion);
    setActiveMediciones(false);
    setActiveCobertura(false);
    setActiveSaludR(false);
    setActiveOtrosE(false);
    setActiveSaludN(false);
    setActiveHorarios(false);
  };

  const toggleClassHorarios = () => {
    setActiveHorarios(!isActiveHorarios);
    setActiveMediciones(false);
    setActiveCobertura(false);
    setActiveSaludR(false);
    setActiveOtrosE(false);
    setActiveSaludN(false);
    setActivePromocion(false);
  };


  
  return (

    <div className="">


                 <div  className='subtitulo'>
                    <h2>Historia Clínica</h2>
                </div>
      {/*======================== Menú ==========================*/}
      <nav className="main-menu sub-menu">
        <ul>
   
          <li className="has-subnav">
            <a  href="#" onClick={toggleClassMediciones}  >
              <i className="bi bi-list"  ></i>
              <span className="nav-text">Antecedentes Patológicos</span>
            </a>

          </li>


          
          <li className="has-subnav">
            <a href="#"  onClick={toggleClassCobertura}>
              <i className="bi bi-list"></i>
              <span className="nav-text">Historia Familiar</span>
            </a>
          </li>

          <li className="has-subnav">
            <a href="#"  onClick={toggleClassSaludR}>
              <i className="bi bi-list"></i>
              <span className="nav-text">Historia Estudios</span>
            </a>
          </li>

          <li className="has-subnav">
            <a href="#"  onClick={toggleClassOtrosE}>
              <i className="bi bi-list"></i>
              <span className="nav-text">Pulso</span>
            </a>
          </li>
          


  
        <li className="has-subnav">
            <a href="#"   onClick={toggleClassSaludN}>
              <i className="bi bi-list"></i>
              <span className="nav-text">Resumen</span>
            </a>
          </li>

                
        </ul>

      </nav>




      <div className={isActiveMediciones ? "" : "mediciones active"}>
     < AntecedentesPatologicos/>
      </div>

      <div className={isActiveCobertura? "" : "cobertura active"}>
       <HistoriaFamiliar />
      </div>


      <div className={isActiveSaludR ? "" : "saludr active"}>
       < HistoriaEstudios />
      </div>


      <div className={isActiveOtrosE ? "" : "otros active"}>
       <Pulso />
      </div>


      <div className={isActiveSaludN ? "" : "saludn active"}>
   

   <Odontograma />
      </div>


    </div>
  )
}

export default MenuHistoria