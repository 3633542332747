import React from "react";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import "../css/MenuNuevo.css";
import Personal from "./RegistrarPersonal";
import RegistrarClinicas from "./RegistrarClinicas";
import RegisterPatients from "./RegistrarPacientes";
import { addClass } from "./Funciones";


function MenuContenido() {

  return (
    <div id="container2">

      <div>
      <div className="titulo-principal">
        <h2>Registros Usuarios </h2>
      </div>

      <div className="menu-secundary">
        <nav>
            <ul>
                <li><button id="btn1"   className="boton-menu"  onClick={() => addClass("usuarios"      ) } >Personal    </button></li> 
                <li><button id="btn2"   className="boton-menu"  onClick={() => addClass("clientes"      ) }>Clinicas     </button></li>
                <li><button id="btn3"   className="boton-menu"  onClick={() => addClass("marcas"        ) }>Pacientes       </button></li>
            </ul>
        </nav>
      </div>
    </div>


           <div className="contenido-dinamico">
            <div id="usuarios" >
            <Personal/>
            </div>
            <div id="clientes" >
            <RegistrarClinicas />
            </div>
            <div id="marcas" >
            <RegisterPatients/>
            </div>
            </div>


    </div>
  );
}

export default MenuContenido;
