import React from 'react'
import { useState } from 'react'
import PlanificacionFamiliar from './PlanificacionFamiliar';
import Detecciones from './Detecciones';
import { Icon } from '@iconify/react';

function MenuConsultasAtenciones(props) {

  const [isActiveMediciones, setActiveMediciones] = useState(true);
  const [isActiveCobertura, setActiveCobertura] = useState(false);


  const toggleClassMediciones = () => {
    setActiveMediciones(!isActiveMediciones);
    setActiveCobertura(false);

  };
  const toggleClassCobertura = () => {
    setActiveCobertura(!isActiveCobertura);
    setActiveMediciones(false);

  };



  return (

    <div className="contenedor">
      <div className='subtitulo'>
        <h2>Consultas y Atenciones</h2>
      </div>
      {/*======================== Menú ==========================*/}
      <nav className="main-menu   sub-menu">
        <ul>
          <li>
            <a href="#" onClick={toggleClassMediciones}  >
              <Icon icon="medical-icon:i-family-practice" color='white' width="30px" className='bi' />
              <span className="nav-text">Planificación Familiar</span>
            </a>
          </li>
          <li className="has-subnav">
            <a href="#" onClick={toggleClassCobertura}>
              <Icon icon="fluent:book-search-20-regular" color='white' width="30px" className='bi' />
              <span className="nav-text" >Detecciones</span>
            </a>
          </li>
        </ul>
      </nav>
      <div className={isActiveMediciones ? "" : "mediciones active"}>
        < PlanificacionFamiliar />
      </div>

      <div className={isActiveCobertura ? "" : "cobertura active"}>
        <Detecciones />
      </div>
    </div>
  )
}

export default MenuConsultasAtenciones