import { BrowserRouter as Router } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./css/App.css";
import "./Administrador-n1/Citas.css"
//============ Rutas Públicas ============
import Login from "./Administrador/Login/Login2";
import Slider from "./Administrador-n1/Home/Slider/Slider";
//============ Rutas Private Públicas ============
import PublicRoutes from "./Routes/PublicRoutes";
//============ Rutas Private Administrador ============
import MenuAdministradores from "./Administrador-n1/Home/MenuLateral/MenuAdministradores";
import AdministradorN1 from "./Routes/ValidaAdministrador";
import MenuContenido from "./Administrador-n1/Usuarios/Registrar/MenuRegistrarUsuarios";
import AdministrarMenuContenido from "./Administrador-n1/Usuarios/Administrar/AdministrarMenuContenido";
import MenuContenidoCitas from './Administrador-n1/Consultas/MenuCotenidoConsultas';
import AdministradorN2 from "./Routes/AdministradorN2";
import AdministradorN3 from "./Routes/AdministradorN3";
import HeaderN3 from "./Administrador-n3/Paginas/HeaderN3";
import MenuUrgencias from "./Administrador-n1/Urgencias/Registrar/MenuUrgencias";
import RegistrarProgramas from "./Administrador-n1/Programas/RegistrarProgramas";
import ReportesUsuarios from "./Administrador-n1/Usuarios/Reportes/ReportesUsuarios";
import AdministrarConsultas from "./Administrador-n1/Consultas/Administrar/AdministracionConsultas";
import AdministracionUrgencias from "./Administrador-n1/Urgencias/Administrar/AdministracionUrgencias";
import AdministrarProgramas from "./Administrador-n1/Programas/Administrar/AdministrarProgramas";
import ReportesProgramasComunicados from "./Administrador-n1/Programas/Reportes/ReportesProgramasComunicados";
import MenAdministrador2 from "./Administrador-n2/MenAdministrador2";
import MenuAdministrarUsuarios from "./Administrador-n1/Usuarios/Administrar/MenuAdministrarUsuarios";
import ReportesConsultas from "./Administrador-n1/Consultas/Reportes/ReportesConsultas";
import ReportesUrgencias from "./Administrador-n1/Urgencias/Reportes/ReportesUrgencias";


function App() {
  

  return (
    <div className="App">

      <Router>
        {/*========================== Páginas Públicas==========================*/}
         <PublicRoutes  path="/" component={Login}/>
        {/*========================== Páginas Administrador N1 HOME==========================*/}
      
         <AdministradorN1 exact   path="/" component={Slider} />
         <AdministradorN1 path="/" component={MenuAdministradores} />
         <AdministradorN1 path="/login" component={Login}/>
    
        {/*========================== Páginas Administrador N1 USUARIOS==========================*/}
        
         <AdministradorN1 exact  path="/registrar_usuarios"   component={MenuContenido}  />
         <AdministradorN1 exact  path="/administrar_usuarios"   component={MenuAdministrarUsuarios}  />





         
         <AdministradorN1 exact  path="/reportes_usuarios"   component={ReportesUsuarios}  />

        {/*========================== Páginas Administrador N1 CONSULTAS==========================*/}
        <AdministradorN1 exact  path="/registrar_consultas" component={MenuContenidoCitas}  />
        <AdministradorN1 exact  path="/administrar_consultas"   component={AdministrarConsultas}  />
        <AdministradorN1 exact  path="/reportes_consultas"   component={ReportesConsultas}  />

        {/*========================== Páginas Administrador N1 URGENCIAS==========================*/}
        <AdministradorN1 exact  path="/urgencias" component={MenuUrgencias}  />
        <AdministradorN1 exact  path="/administrar_urgencias"   component={AdministracionUrgencias}  />
        <AdministradorN1 exact  path="/reportes_urgencias"   component={ReportesUrgencias}  />

        {/*========================== Páginas Administrador N1 URGENCIAS==========================*/}
         <AdministradorN1 exact  path="/programas" component={RegistrarProgramas}  />  
         <AdministradorN1 exact  path="/administrar_programas"   component={AdministrarProgramas}  />
         <AdministradorN1 exact  path="/administrar_reportes" component={ReportesProgramasComunicados}  />
         



         
         {/*========================== Páginas Administrador N2==========================*/}

          <AdministradorN2 path="/" component={MenAdministrador2} />
          <AdministradorN3 path="/" component={HeaderN3} />
     


      </Router>



    </div>
  );
}

export default App;
