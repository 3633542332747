import React from 'react'

function OtrosEventos() {
  return (
    <div className='data-consulta'>
      <div className='subtitulo'>
        <h3>Otros Eventos</h3>
      </div>
      <div className='div-direccion'>
        <div className='relleno salud'>
          <p>Primera vez:</p>
          <select name="select">
            <option value="value1">Seleccione una Opción</option>
          </select>
        </div>
        <br />
        <div className='relleno  salud'>
          <p>Subsecuente:</p>
          <select name="select">
            <option value="value1">Seleccione una Opción</option>
          </select>
          <br />
          <br />
        </div>
      </div>
    </div>
  )
}

export default OtrosEventos