import React, { useState } from 'react'
import Logo from "../../../images/logo.png"
import "../css/menuLateral.css"




function MenuPrueba() {

  const [isActiveMediciones, setActiveMediciones] = useState(false);
  const [isActiveCobertura, setActiveCobertura] = useState(false);
  const [isActiveSaludR, setActiveSaludR] = useState(false);
  const [isActiveOtrosE, setActiveOtrosE] = useState(false);
  const [isActiveSaludN, setActiveSaludN] = useState(false);
  const [isActivePromocion, setActivePromocion] = useState(false);
  const [isActiveHorarios, setActiveHorarios] = useState(false);
  


  const toggleClassMediciones = () => {
    setActiveMediciones(!isActiveMediciones);
    setActiveCobertura(false);
    setActiveSaludR(false);
    setActiveOtrosE(false);
    setActiveSaludN(false);
    setActivePromocion(false);
    setActiveHorarios(false);
  };
  const toggleClassCobertura = () => {
    setActiveCobertura(!isActiveCobertura);
    setActiveMediciones(false);
    setActiveSaludR(false);
    setActiveOtrosE(false);
    setActiveSaludN(false);
    setActivePromocion(false);
    setActiveHorarios(false);
  };

  const toggleClassSaludN = () => {
    setActiveSaludN(!isActiveSaludN);
    setActiveMediciones(false);
    setActiveCobertura(false);
    setActiveSaludR(false);
    setActiveOtrosE(false);
    setActivePromocion(false);
    setActiveHorarios(false);
  };

  return (
    <div className="contenedor">


         
      {/*======================== Menú ==========================*/}
      <nav className="main-menu">
        <ul>
     {/*      <div className="administrador-user">
            <li>
              <i className="bi bi-person-circle fa-2x"></i>
              <span className="nav-text">Administrador</span>
            </li>
          </div> */}

          <li className='has-subnav logo'>
            <a  href="/" onClick={toggleClassMediciones}  >
              <i className="logo"><img src={Logo} className="logo" /></i>
              <span className="nav-text logo"><p  className='titulo logo'>MEDCUR</p></span>
            </a>

          </li>


          
          <li className="has-subnav">
            <a href="/"  onClick={toggleClassCobertura}>
              <i className="bi bi-house-fill"></i>
              <span className="nav-text">Inicio</span>
            </a>
          </li>




          <li className="has-subnav">
            <a href="#usuarios-menu" data-toggle="collapse" aria-expanded="false" className="dropdown-toggle"   >
              <i className="bi bi-people-fill" />
              <span className="nav-text">Usuarios</span>
            </a>
            <ul className="collapse list-unstyled" id="usuarios-menu">
              <li>
                <a href="/registrar_usuarios">
                <i className="bi bi-person-plus-fill" />
              <span className="nav-text">Registrar</span>
                </a>
              </li>



              <li>
                <a href="/administrar_usuarios">
                <i className="bi bi-person-video2" />
              <span className="nav-text">Administrar</span>
                </a>
              </li>


              <li>
                <a href="/reportes_usuarios">
                <i className="bi bi-person-bounding-box" />
              <span className="nav-text">Reportes</span>
                </a>
              </li>
            </ul>
          </li>




          <li className="has-subnav">
            <a href="#consultas" data-toggle="collapse" aria-expanded="false" className="dropdown-toggle"   >
              <i className="bi bi-list-task" />
              <span className="nav-text">Consultas</span>
            </a>
            <ul className="collapse list-unstyled" id="consultas">
              <li>
                <a href="/registrar_consultas">
                <i className="bi bi-person-plus-fill" />
              <span className="nav-text">Registrar</span>
                </a>
              </li>



              <li>
                <a href="/administrar_consultas">
                <i className="bi bi-person-video2" />
              <span className="nav-text">Administrar</span>
                </a>
              </li>


              <li>
                <a href="/reportes_consultas">
                <i className="bi bi-person-bounding-box" />
              <span className="nav-text">Reportes</span>
                </a>
              </li>



            </ul>
          </li>





          <li className="has-subnav">
            <a href="#urgencias" data-toggle="collapse" aria-expanded="false" className="dropdown-toggle"  >
              <i className="bi bi-file-medical" />
              <span className="nav-text">Urgencias</span>
            </a>
            <ul className="collapse list-unstyled" id="urgencias">
              <li>
                <a href="/urgencias">
                <i className="bi bi-person-plus-fill" />
              <span className="nav-text">Registrar</span>
                </a>
              </li>



              <li>
                <a href="/administrar_urgencias">
                <i className="bi bi-person-video2" />
              <span className="nav-text">Administrar</span>
                </a>
              </li>


              <li>
                <a href="/reportes_urgencias">
                <i className="bi bi-person-bounding-box" />
              <span className="nav-text">Reportes</span>
                </a>
              </li>



            </ul>
          </li>





          <li className="has-subnav">
            <a href="#programas" data-toggle="collapse" aria-expanded="false" className="dropdown-toggle" >
              <i className="bi bi-file-earmark-medical" />
              <span className="nav-text">Programas</span>
            </a>
            <ul className="collapse list-unstyled" id="programas">
              <li>
                <a href="/programas">
                <i className="bi bi-person-plus-fill" />
              <span className="nav-text">Registrar</span>
                </a>
              </li>



           <li>
                <a href="/administrar_programas">
                <i className="bi bi-person-video2" />
              <span className="nav-text">Administrar</span>
                </a>
              </li>


              <li>
                <a href="/administrar_reportes">
                <i className="bi bi-person-bounding-box" />
              <span className="nav-text">Reportes</span>
                </a>
              </li>



            </ul>
          </li>


          


  
        <li className="has-subnav">
            <a href="#"   onClick={toggleClassSaludN}>
              <i className="bi bi-bell"></i>
              <span className="nav-text">Notificaciones</span>
            </a>
          </li>


          


                    
      {/* 
           <li className="has-subnav">
            <a href="#perfil" data-toggle="collapse"   >
              <i className="bi bi-question-diamond" />
              <span className="nav-text">Menú</span>
            </a>
            <ul className="collapse list-unstyled" id="perfil">
              <li>
                <a href="/">
                <i className="bi bi-question-diamond" />
              <span className="nav-text">Sub-menú</span>
                </a>
              </li>
            </ul>
          </li>




 */}

          {/*======================== Salir ==========================*/}
        </ul>
     <ul className="logout">
          <li>
            <a href="#" /*  onClick={cierreSesion} */>
              <i className="fa fa-power-off fa-2x"></i>
              <span className="nav-text">Salir</span>
            </a>
          </li>
        </ul> 
      </nav>

{/* <div className='data-consulta'>
<DatosConsulta/>
</div> */}


      <div className={isActiveMediciones ? "" : "mediciones active"}>

      </div>

      <div className={isActiveCobertura? "" : "cobertura active"}>
       
      </div>


      <div className={isActiveSaludR ? "" : "saludr active"}>
   
      </div>


      <div className={isActiveOtrosE ? "" : "otros active"}>
      
      </div>


      <div className={isActiveSaludN ? "" : "saludn active"}>
   


      </div>


    </div>

  )
}

export default MenuPrueba