import React, { useState } from 'react'
import "../../css/menu.css"
import Mediciones from './Mediciones'
import DatosConsulta from './DatosConsulta'
import Cobertura from './Cobertura'
import SaludReproductiva from './SaludReproductiva'
import OtrosEventos from './OtrosEventos'
import SaludNino from './SaludNino'
import PromocionSalud from './PromocionSalud'
import ConsultaHorario from './ConsultaHorario'





function MenuConsulta(props) {

  const [isActiveMediciones, setActiveMediciones] = useState(true);
  const [isActiveCobertura, setActiveCobertura] = useState(false);
  const [isActiveSaludR, setActiveSaludR] = useState(false);
  const [isActiveOtrosE, setActiveOtrosE] = useState(false);
  const [isActiveSaludN, setActiveSaludN] = useState(false);
  const [isActivePromocion, setActivePromocion] = useState(false);
  const [isActiveHorarios, setActiveHorarios] = useState(false);



  const toggleClassMediciones = () => {
    setActiveMediciones(!isActiveMediciones);
    setActiveCobertura(false);
    setActiveSaludR(false);
    setActiveOtrosE(false);
    setActiveSaludN(false);
    setActivePromocion(false);
    setActiveHorarios(false);
  };
  const toggleClassCobertura = () => {
    setActiveCobertura(!isActiveCobertura);
    setActiveMediciones(false);
    setActiveSaludR(false);
    setActiveOtrosE(false);
    setActiveSaludN(false);
    setActivePromocion(false);
    setActiveHorarios(false);
  };

  const toggleClassSaludR = () => {
    setActiveSaludR(!isActiveSaludR);
    setActiveMediciones(false);
    setActiveCobertura(false);
    setActiveOtrosE(false);
    setActiveSaludN(false);
    setActivePromocion(false);
    setActiveHorarios(false);
  };

  const toggleClassOtrosE = () => {
    setActiveOtrosE(!isActiveOtrosE);
    setActiveMediciones(false);
    setActiveCobertura(false);
    setActiveSaludR(false);
    setActiveSaludN(false);
    setActivePromocion(false);
    setActiveHorarios(false);
  };

  const toggleClassSaludN = () => {
    setActiveSaludN(!isActiveSaludN);
    setActiveMediciones(false);
    setActiveCobertura(false);
    setActiveSaludR(false);
    setActiveOtrosE(false);
    setActivePromocion(false);
    setActiveHorarios(false);
  };

  const toggleClassPromocion = () => {
    setActivePromocion(!isActivePromocion);
    setActiveMediciones(false);
    setActiveCobertura(false);
    setActiveSaludR(false);
    setActiveOtrosE(false);
    setActiveSaludN(false);
    setActiveHorarios(false);
  };

  const toggleClassHorarios = () => {
    setActiveHorarios(!isActiveHorarios);
    setActiveMediciones(false);
    setActiveCobertura(false);
    setActiveSaludR(false);
    setActiveOtrosE(false);
    setActiveSaludN(false);
    setActivePromocion(false);
  };



  return (

    <div className="contenedor">
      {/*======================== Menú ==========================*/}
      <nav className="main-menu  sub-menu">
        <ul>
          <li className="has-subnav">
            <a href="#" onClick={toggleClassMediciones}  >
              <i className="fa fa-heartbeat"  ></i>
              <span className="nav-text">Mediciones</span>
            </a>

          </li>
          <li className="has-subnav">
            <a href="#" onClick={toggleClassCobertura}>
              <i className="bi bi-list"></i>
              <span className="nav-text">Cobertura</span>
            </a>
          </li>

          <li className="has-subnav">
            <a href="#" onClick={toggleClassSaludR}>
              <i className="bi bi-list"></i>
              <span className="nav-text">Salud Reproductiva</span>
            </a>
          </li>

          <li className="has-subnav">
            <a href="#" onClick={toggleClassOtrosE}>
              <i className="bi bi-list"></i>
              <span className="nav-text">Otros Eventos</span>
            </a>
          </li>

          <li className="has-subnav">
            <a href="#" onClick={toggleClassSaludN}>
              <i className="bi bi-list"></i>
              <span className="nav-text">Salud del niño</span>
            </a>
          </li>


          <li className="has-subnav">
            <a href="#" onClick={toggleClassPromocion}>
              <i className="bi bi-list"></i>
              <span className="nav-text">Promoción de la Salud</span>
            </a>
          </li>

          <li className="has-subnav">
            <a href="#" onClick={toggleClassHorarios}>
              <i className="bi bi-activity"></i>
              <span className="nav-text">Asignacion de Fecha y Hora</span>
            </a>
          </li>
        </ul>

      </nav>

      <div className=''>
        <DatosConsulta />
      </div>


      <div className={isActiveMediciones ? "" : "mediciones active"}>
        <Mediciones />
      </div>

      <div className={isActiveCobertura ? "" : "cobertura active"}>
        <Cobertura />
      </div>


      <div className={isActiveSaludR ? "" : "saludr active"}>
        <SaludReproductiva />
      </div>


      <div className={isActiveOtrosE ? "" : "otros active"}>
        <OtrosEventos />
      </div>


      <div className={isActiveSaludN ? "" : "saludn active"}>
        <SaludNino />
      </div>


      <div className={isActivePromocion ? "" : "promocion active"}>
        <PromocionSalud />
      </div>


      <div className={isActiveHorarios ? "" : "horarios active"}>
        <ConsultaHorario />
      </div>

    </div>
  )
}

export default MenuConsulta