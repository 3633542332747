import React from "react";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import { addClass } from "../../Usuarios/Registrar/Funciones";
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import AdministrarNotasMedicas from "./AdministrarNotasMedicas";
import  AdministrarOrdenesInternamiento from "./AdministrarOrdenesInternamiento";




function  AdministracionUrgencias() {

  return (
    <div id="container2">
      <div className="titulo-principal">
        <h2>Administrar Urgencias </h2>
      </div>

      <div className="menu-secundary">
        <nav>
            <ul>
                <li><button id="btn1"   className="boton-menu"  onClick={() => addClass("usuarios"      ) } >Notas médicas iniciales de Urgencias   </button></li> 
                <li><button id="btn2"   className="boton-menu"  onClick={() => addClass("clientes"      ) }>Orden de Internamiento    </button></li>
            </ul>
        </nav>
      </div>

      <div className="contenido-dinamico">
             <div id="usuarios"  className="contenido-din" >
             <div className="personal">
             
             <AdministrarNotasMedicas />
                    </div>
            </div>


            <div id="clientes"  className="contenido-din" >
            <div className="personal">
 
 {/*========================== Llamado al Componente ==========================*/}

< AdministrarOrdenesInternamiento />
</div>
           
            </div>

    </div>
 
 


    </div>
  );
}

export default AdministracionUrgencias;
