import React from "react";
import Carousel from "react-bootstrap/Carousel";
import Comunicados from "../Comunicados/Comunicados";
import imgSlider from "../../../images/slider.jpg";
import "../css/slider.css"

function Slider() {
  return (
    <div id="slider">

      <div className="">
        <Carousel variant="dark">
          <Carousel.Item>
            <img
              className="d-block w-100   h-100"
              src={imgSlider}
              alt="Primer Slider"
            />
            <Carousel.Caption>
              <h5 className="slider-text">Primer Slider</h5>
              <p className="slider-text">Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
            </Carousel.Caption>
          </Carousel.Item>

          <Carousel.Item>
            <img
              className="d-block w-100  h-100"
              src={imgSlider}
              alt="Segundo Slider"
            />
            <Carousel.Caption>
              <h5 className="slider-text">Segundo Slider</h5>
              <p  className="slider-text">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
            </Carousel.Caption>
          </Carousel.Item>

          <Carousel.Item>
            <img
              className="d-block w-100   h-100"
              src={imgSlider}
              alt="ercer Sldier"
            />
            <Carousel.Caption>
              <h5 className="slider-text">Tercer Slider</h5>
              <p className="slider-text">
                Praesent commodo cursus magna, vel scelerisque nisl consectetur.
              </p>
            </Carousel.Caption>
          </Carousel.Item>
        </Carousel>
      </div>

      <div>
        <Comunicados />
      </div>
    </div>
  );
}

export default Slider;
