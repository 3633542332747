import React from 'react'


function Comunicados() {
  return (
    <div className='comunicados'>
      <br />
      <div className='div-direccion'>
        <div className='relleno  '>
          <span>
            <p>Fecha</p>
            <input  id="box" className='input-direccion' placeholder='' />
          </span>
        </div>
        <br />
        <div className='relleno'>
          <span>
            <p> CLUES</p>
            <input   id="box" className='input-direccion' placeholder='' />
          </span>
        </div>
        <br />
        <div className='relleno completo'>
          <span>
            <p>Nombre Unidad</p>
            <input   id="box" className='input-direccion' placeholder='' />
          </span>
        </div>
      </div>


      <div className='cargar-imagen'>
        <p>Titulo:</p>
        <input />
        <p>Detalle:</p>
        <textarea />
        <p>Imagen:</p>
        <input type="file" />
      </div>
      <div className='preview'>
        <img src='https://images.pexels.com/photos/208518/pexels-photo-208518.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1' />
      </div>

      <div className='enviar'>
        
      <button className='btn btn-primary registrar'>
        Guardar
      </button>

      <br/>
      <br/>

      </div>




    </div>
  )
}

export default Comunicados