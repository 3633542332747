import React from 'react'

function Mediciones() {


  return (
    <div className='data-consulta'  >
      <div className='subtitulo'>
        <h3>Mediciones</h3>
      </div>
      <div className='div-direccion'>
        <div className='relleno med'>
          <p>Peso(kg).</p>
          <input className='input-direccion' placeholder='Ingrese Peso (kg).' />
        </div>
        <br />
        <div className='relleno med '>
          <p>Talla (cms).</p>
          <input className='input-direccion' placeholder='Ingrese Talla (cms)' />
        </div>
        <div className='relleno med'>
          <p>Somatometría</p>
          <input className='input-direccion' placeholder='Ingrese Somatometría' />
        </div>
        <br />
      </div>

      <br />


      <div className='box'>
        <div className='dividir'>
          <div className='relleno div'>
            <p>Discapacidad:</p>
            <select name="select">
              <option value="value1">Seleccione una Opción</option>
            </select>
          </div>
          <br />

          <div className='relleno div'>
            <p> Clave CIE 10:</p>
            <select name="select">
              <option value="value1">Seleccione una Opción</option>
            </select>
          </div>
          <br />
          <div className='relleno div'>
            <p> Programa según motivo;</p>
            <select name="select">
              <option value="value1">Seleccione una Opción</option>
            </select>
          </div>
          <br />
        </div>
      </div>


      <div className='caja-info'>
        <div className='relleno caja'>
          <p> Diagnostico:</p>
          <textarea name="textarea" rows="8" cols="30"></textarea>
        </div>
      </div>
    </div>
  )
}

export default Mediciones