import React from 'react'
import '../../css/PlanificacionFamiliar.css'

function PlanificacionFamiliar() {
    return (
        <div className='container-planificacionFam'>
            <div className='subtitulo'>
                <h3>Consultas y atenciones de Planificación familiar
                    SINBA-SIS-CAPF-P</h3>
            </div>
            <div className='container-izq-der'>
                <div className='izquierdo'>
                    <div className='relleno salud'>
                        <p>RT (Relación temporal por motivo PF) :</p>
                        <select name="select">
                            <option value="value1">Seleccione una Opción</option>
                        </select>
                    </div>
                    <div className='relleno salud'>
                        <p>Orientación - Consejería :</p>
                        <input className='input-direccion' placeholder='' />
                    </div>
                    <div className='relleno salud'>
                        <p>Vasectomía sin bisturí :</p>
                        <input className='input-direccion' placeholder='' />
                    </div>
                </div>
                <div className='derecho'>
                    <div className='relleno salud'>
                        <p>Anticoncepción de emergencia :</p>
                        <input className='input-direccion' placeholder='' />
                    </div>

                    <div className='relleno salud'>
                        <p>Anticoncepción de emergencia :</p>
                        <input className='input-direccion' placeholder='' />
                    </div>
                </div>
            </div>
            <div className='subtitulo'>
                <h3>Métodos entregados</h3>
            </div>
            <div className='container-izq-der'>

                <div className='izquierdo'>



                    <div className='relleno salud'>
                        <p>Oral :</p>
                        <input className='input-direccion' placeholder='' />
                    </div>
                    <div className='relleno salud'>
                        <p>Inyectable bimestral :</p>
                        <input className='input-direccion' placeholder='' />
                    </div>
                    <div className='relleno salud'>
                        <p>Parche dérmico :</p>
                        <input className='input-direccion' placeholder='' />
                    </div>
                    <div className='relleno salud'>
                        <p>DIU Medicado :</p>
                        <input className='input-direccion' placeholder='' />
                    </div>
                    <div className='relleno salud'>
                        <p>Preservativo femenino :</p>
                        <input className='input-direccion' placeholder='' />
                    </div>
                    <div className='relleno salud'>
                        <p>Quirúrgico :</p>
                        <input className='input-direccion' placeholder='' />
                    </div>



                </div>



                <div className='derecho'>
                    <div className='relleno salud'>
                        <p>Inyectable mensual :</p>
                        <input className='input-direccion' placeholder='' />
                    </div>
                    <div className='relleno salud'>
                        <p>Implante subdérmico :</p>
                        <input className='input-direccion' placeholder='' />
                    </div>
                    <div className='relleno salud'>
                        <p>DIU :</p>
                        <input className='input-direccion' placeholder='' />
                    </div>
                    <div className='relleno salud'>
                        <p>Preservativo :</p>
                        <input className='input-direccion' placeholder='' />
                    </div>
                    <div className='relleno salud'>
                        <p>Otro Método :</p>
                        <input className='input-direccion' placeholder='' />
                    </div>
                </div>
            </div>

            <div className='registrar'>
                <br />
                <button type="button" className="btn btn-primary registrar">GUARDAR</button>
            </div>

        </div>

    )
}

export default PlanificacionFamiliar