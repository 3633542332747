import React from "react";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import DatosPaciente from '../../Consultas/Registrar/DatosPaciente';
import SubMenuUrgencias from '../SubMenuUrgencias';
import OrdenInternamiento from '../Registrar/ordenInternamiento';




import { addClass } from "../../Usuarios/Registrar/Funciones";

function MenuUrgencias() {

    return (
        <div id="container2">

            <div>
                <div className="titulo-principal">
                    <h2>Registros Urgencias </h2>
                </div>

                <div className="menu-secundary">
                    <nav>
                        <ul>
                            <li><button id="btn1" className="boton-menu" onClick={() => addClass("usuarios")} >Nota Médica Inicial de Urgencias   </button></li>
                            <li><button id="btn2" className="boton-menu" onClick={() => addClass("clientes")}>Orden de Internamiento  </button></li>
                        </ul>
                    </nav>
                </div>
            </div>


            <div className="contenido-dinamico">
                <div id="usuarios" >

                    <div className="personal ">

                        <DatosPaciente />
                        <div className='prueba derecha '>
                            <SubMenuUrgencias />
                        </div>
                    </div>

                </div>
                <div id="clientes" >
                    <div className="">
                        <br />

                        <DatosPaciente />
                        {/*========================== Llamado al Componente ==========================*/}
                        <div className='prueba derecha '>

                            <OrdenInternamiento />

                        </div>



                    </div>

                </div>

            </div>


        </div>
    );
}

export default MenuUrgencias;
