import React from 'react'
import "./urgencias.css";
import DatosConsulta from '../Consultas/Registrar/ConsultasExternas/DatosConsulta'
import DatosAdicionales from './Registrar/DatosAdicionales';
import DatosGenerales from './Registrar/NotaMedica/DatosGenerales';
import Diagnostico from './Registrar/NotaMedica/Diagnostico';
import SignosVitales from './Registrar/NotaMedica/SignosVitales';


import { useState } from 'react'




function SubMenuUrgencias(props) {

  const [isActiveMediciones, setActiveMediciones] = useState(true);
  const [isActiveCobertura, setActiveCobertura] = useState(false);
  const [isActiveSaludR, setActiveSaludR] = useState(false);



  const toggleClassMediciones = () => {
    setActiveMediciones(!isActiveMediciones);
    setActiveCobertura(false);
    setActiveSaludR(false);

  };
  const toggleClassCobertura = () => {
    setActiveCobertura(!isActiveCobertura);
    setActiveMediciones(false);
    setActiveSaludR(false);

  };

  const toggleClassSaludR = () => {
    setActiveSaludR(!isActiveSaludR);
    setActiveMediciones(false);
    setActiveCobertura(false);

  };


  return (

    <div className="">
      {/*======================== Menú ==========================*/}
      <nav className="main-menu sub-menu">
        <ul>
          <li className="has-subnav">
            <a href="#" onClick={toggleClassMediciones}  >
              <i className="fa fa-heartbeat"  ></i>
              <span className="nav-text">Datos Generales</span>
            </a>
          </li>
          <li className="has-subnav">
            <a href="#" onClick={toggleClassCobertura}>
              <i className="bi bi-list"></i>
              <span className="nav-text">Diagnóstico</span>
            </a>
          </li>
          <li className="has-subnav">
            <a href="#" onClick={toggleClassSaludR}>
              <i className="bi bi-list"></i>
              <span className="nav-text">SignosVitales</span>
            </a>
          </li>
        </ul>
      </nav>

      <div className='data-consulta'>
        <DatosAdicionales />
<div className='data-consul'>
<DatosConsulta />
</div>
        
  
      </div>
      <div className={isActiveMediciones ? "" : "mediciones active"}>
        <DatosGenerales />
      </div>
      <div className={isActiveCobertura ? "" : "cobertura active"}>
        <Diagnostico />
      </div>
      <div className={isActiveSaludR ? "" : "saludr active"}>
        <SignosVitales />
      </div>
    </div>
  )
}

export default SubMenuUrgencias