import React from 'react'

function OrdenInternamiento() {
    return (
        <div className='orden-internamiento'>
            <div className='div-direccion'>
                <div className='relleno'>
                    <span>
                        <p>Fecha</p>
                        <input id="box" className='input-direccion' placeholder='' />
                    </span>
                </div>
                <br />
                <div className='relleno'>
                    <span>
                        <p>CLUES</p>
                        <input id="box" className='input-direccion' placeholder='' />
                    </span>
                </div>


                <br />
                <div className='relleno'>
                    <span>
                        <p>Nombre Unidad</p>
                        <input id="box" className='input-direccion' placeholder='' />
                    </span>
                </div>
            </div>
            <br />

            <div className='subtitulo'>
                <h2>Médico tratante</h2>
            </div>





            <div className='div-direccion'>
                <div className='relleno'>
                    <span>
                        <p>Fecha</p>
                        <input id="box" className='input-direccion' placeholder='' />
                    </span>
                </div>
                <br />
                <div className='relleno'>
                    <span>
                        <p>CLUES</p>
                        <input id="box" className='input-direccion' placeholder='' />
                    </span>
                </div>


                <br />
                <div className='relleno'>
                    <span>
                        <p>Nombre Unidad</p>
                        <input id="box" className='input-direccion' placeholder='' />
                    </span>
                </div>
            </div>
            <br />

            <br />

            <div className='div-direccion'>
                <div className='relleno'>
                    <span>
                        <p>Cédula Profesional</p>
                        <input id="box" className='input-direccion' placeholder='' />
                    </span>
                </div>
                <br />

                <div className='relleno  completo'>
                    <span>
                        <p>Servicio</p>
                        <input id="box" className='input-direccion' placeholder='' />
                    </span>
                </div>

                <br />
            </div>
            <br />
            <br />
            <div className='div-direccion'>
                <div className='relleno salud'>

                    <p>Hora de ingreso :</p>
                    <select name="select">
                        <option value="value1">Seleccione una Opción</option>
                    </select>
                </div>
                <br />
                <div className='relleno  salud'>

                    <p>Servicio al que será hospitalizado :</p>
                    <select name="select">
                        <option value="value1">Seleccione una Opción</option>
                    </select>
                    <br />
                    <br />
                </div>
            </div>


            <div className='relleno salud'>
                <p>Diagnóstico :</p>
                <textarea name="textarea" rows="6" ></textarea>
            </div>
            <br />
            <div className='relleno salud'>
                <p>Indicaciones :</p>
                <textarea name="textarea" rows="6" ></textarea>

                <br />
                <br />
            </div>



            <div className='div-direccion'>
                <div className='relleno salud'>

                    <p>Firma (Médico tratante) :</p>
                    <input className='select-file' id="image-file" type="file" />
                </div>
                <br />
                <div className='relleno  salud'>

                    <p>Vo. Bo. Jefe de Servicio :</p>
                    <input className='select-file' id="image-file" type="file" />

                    <br />
                    <br />
                </div>
            </div>

            <div className='enviar'>
                <button type="button" className="btn btn-primary registrar">GUARDAR</button>
                <br />
                <br />
            </div>



        </div>
    )
}
export default OrdenInternamiento