import React from 'react'
import { Table } from 'react-super-responsive-table';

////

function AntecedentesPatologicos() {
    return (
        <div className='historia-clinica'>


 <div  className='subtitulo'>
      <h3>Antecedentes patológicos generales</h3>
   </div>


            <div className='titulo'>
               
                <p>¿Ha existido alguno de los siguientes casos en la familia del paciente?</p>

            </div>





            <div className='div-direccion'>
            <div className='relleno salud'>
               
              <p>Patología:</p>    
              <select id="patología" name="">
                    <option value={0}>--Seleccione Casos</option>
                    <option value={1}>Problemas del Corazón</option>
                    <option value={2}>Diabetes</option>
                </select>

            </div>
            <br />
            <div className='relleno  salud'>
           
            <p>Tipo de Casos:</p>
           
            <Table >
                    <thead>
                        <tr >
                            <th className='color-table'> Casos</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td className='border-td'>
                                <p>Diabetes</p>


                                <p>Enfermedades del Corazón</p>
                            </td>
                        </tr>
                    </tbody>
                </Table>
    
           <br />
            <br />
            </div>   
        </div>


        </div>
    )
}

export default AntecedentesPatologicos