import React from 'react'

function SignosVitales() {
  return (
    <div className='nota-medica'>
      <div className='subtitulo'>
        <h3>Signos Vitales</h3>
      </div>
      <div className='div-direccion'>
        <div className='relleno med'>
          <p>TA (mmHg):</p>
          <input className='input-direccion' placeholder='Ingrese Peso (kg).' />
        </div>
        <br />
        <div className='relleno med '>
          <p>TAM (mmHg):</p>
          <input className='input-direccion' placeholder='Ingrese Talla (cms)' />
        </div>
        <div className='relleno med'>
          <p>WP (mmHg):</p>
          <input className='input-direccion' placeholder='Ingrese Somatometría' />
          <br />
        </div>
      </div>
      <div className='div-direccion'>
        <br />
        <div className='relleno med'>
          <p>FC (/min):</p>
          <input className='input-direccion' placeholder='Ingrese Peso (kg).' />
        </div>
        <br />
        <div className='relleno med '>
          <p>PR (r/min):</p>
          <input className='input-direccion' placeholder='Ingrese Talla (cms)' />
        </div>
        <div className='relleno med'>
          <p>TEMP (°C):</p>
          <input className='input-direccion' placeholder='Ingrese Somatometría' />
          <br />
        </div>
        <br />
      </div>

      <div className='div-direccion'>
        <div className='relleno med'>
          <p>Peso (Kg):</p>
          <input className='input-direccion' placeholder='Ingrese Peso (kg).' />

        </div>
        <br />
        <div className='relleno med '>
          <p>Talla (cm):</p>
          <input className='input-direccion' placeholder='Ingrese Talla (cms)' />
        </div>
        <div className='relleno med'>
          <p>PC (CM):</p>
          <input className='input-direccion' placeholder='Ingrese Somatometría' />
          <br />
        </div>
        <br />
      </div>

      <div className='div-direccion'>
        <div className='relleno med'>
          <p>PT (cm):</p>
          <input className='input-direccion' placeholder='Ingrese Peso (kg).' />
        </div>
        <br />
        <div className='relleno med '>
          <p>PA (cm):</p>
          <input className='input-direccion' placeholder='Ingrese Talla (cms)' />
        </div>
        <div className='relleno med'>
          <p>N2 (cm):</p>
          <input className='input-direccion' placeholder='Ingrese Somatometría' />
          <br />
        </div>
        <br />
      </div>
      <div className='div-direccion'>
        <div className='relleno med'>
          <p>ST (cm):</p>
          <input className='input-direccion' placeholder='Ingrese Peso (kg).' />
        </div>
        <br />
        <div className='relleno med '>
          <p>PUM (d/m/a):</p>
          <input className='input-direccion' placeholder='Ingrese Talla (cms)' />
        </div>
        <div className='relleno med'>
          <p>FPP (d/m/a):</p>
          <input className='input-direccion' placeholder='Ingrese Somatometría' />
          <br />
        </div>
        <br />
      </div>
      <div className='div-direccion'>
        <div className='relleno med'>
          <p>FU (cm):</p>
          <input className='input-direccion' placeholder='Ingrese Peso (kg).' />

        </div>
        <br />
        <div className='relleno med '>
          <p>SDG (SEM):</p>
          <input className='input-direccion' placeholder='Ingrese Talla (cms)' />
          <br />
          <br />
        </div>
        <br />
      </div>

      <div className='enviar'>
                <button type="button" className="btn btn-primary registrar">GUARDAR</button>
                <br />
                <br />
            </div>


    </div>
  )
}

export default SignosVitales