import React from 'react'

function DatosConsulta() {



  return (
    <div className='data-consulta'>
  
      <div className='subtitulo'>
        <h2>Datos de la consulta</h2>
      </div>
      <div className='div-direccion'>
        <div className='relleno  '>
          <span>
            <p>Fecha</p>
            <input  id="box" className='input-direccion' placeholder='' />
          </span>
        </div>
        <br />
        <div className='relleno'>
          <span>
            <p> CLUES</p>
            <input   id="box" className='input-direccion' placeholder='' />
          </span>
        </div>
        <br />
        <div className='relleno completo'>
          <span>
            <p>Nombre Unidad</p>
            <input   id="box" className='input-direccion' placeholder='' />
          </span>
        </div>
      </div>
      <div className='subtitulo'>
        <h2>Prestador del servicio</h2>
      </div>


      <div className='div-direccion'>
        <div className='relleno'>
          <span>
            <p>Nombre Completo</p>
            <input   id="box" className='input-direccion' placeholder='' />
          </span>
        </div>
        <br />

        <div className='relleno'>
          <span>
            <p> CURP</p>
            <input   id="box" className='input-direccion' placeholder='' />
          </span>
        </div>

        <br />

        <div className='relleno '>
          <span>
            <p>Tipo de Personal</p>
            <input   id="box" className='input-direccion' placeholder='' />
          </span>
        </div>
      </div>




      <br />


      <div className='div-direccion'>
        <div className='relleno'>
          <span>
            <p>Cédula Profesional</p>
            <input   id="box"className='input-direccion' placeholder='' />
          </span>
        </div>
        <br />

        <div className='relleno  completo'>
          <span>
            <p> Servicio</p>
            <input   id="box" className='input-direccion' placeholder='' />
          </span>
        </div>

        <br />
      </div>
      <br />







    </div>
  )
}

export default DatosConsulta