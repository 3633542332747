import React, { PureComponent } from 'react';
import { PieChart, Pie, Sector, Cell, ResponsiveContainer } from 'recharts';
    

function ReportesUsuarios() {
const data01 = [
  { name: 'Group A', value: 400 },
  { name: 'Group B', value: 300 },
  { name: 'Group C', value: 300 },
  { name: 'Group D', value: 200 },
];
const data02 = [
  { name: 'A1', value: 100 },
  { name: 'A2', value: 300 },
  { name: 'B1', value: 100 },
  { name: 'B2', value: 80 },
  { name: 'B3', value: 40 },
  { name: 'B4', value: 30 },
  { name: 'B5', value: 50 },
  { name: 'C1', value: 100 },
  { name: 'C2', value: 200 },
  { name: 'D1', value: 150 },
  { name: 'D2', value: 50 },
];

    return (
        <div className='reportes'>
            <h2> Reportes  de Programas y Comunicados</h2>

            <div className='info-graficas'>
                <div className='reportes-seleccion'>
                    <div className='tipo-usuario'>
                        <select className='reportes' >
                            <option disabled selected> Tipo de Usuario</option>
                            <option>Admin</option>
                        </select>
                        <select className='reportes' >
                            <option disabled selected>  Tipo de Reporte</option>
                            <option>Admin</option>
                        </select>
                    </div>
                    <div className='fecha-usuario'>
                        <select className='reportes' >
                            <option disabled selected> Seleccionar Fecha de Inicio</option>
                            <option>-----</option>
                        </select>
                    </div>

                    <div className='fecha-usuario-final'>
                        <select className='reportes' >
                            <option disabled selected>   Seleccionar Fecha de Fin</option>
                            <option>-----</option>
                        </select>
                    </div>

                </div>
            </div>



            <div className='grafica'>
                <div className='grafica1'>
               
        <PieChart width={400} height={400}>
          <Pie data={data01} dataKey="value" cx="50%" cy="50%" outerRadius={60} fill="#8884d8" />
          <Pie data={data02} dataKey="value" cx="50%" cy="50%" innerRadius={70} outerRadius={90} fill="#82ca9d" label />
        </PieChart>

                </div>
                <div className='grafica2'>
              
        <PieChart width={400} height={400}>
          <Pie data={data01} dataKey="value" cx="50%" cy="50%" outerRadius={60} fill="#8884d8" />
          <Pie data={data02} dataKey="value" cx="50%" cy="50%" innerRadius={70} outerRadius={90} fill="#82ca9d" label />
        </PieChart>


            </div>




            <div className='informacion-graficas'>
                <div className='tabla-reportes'>
                    <table>
                        <thead>
                            <tr>
                                <th className='titulo-reportes'>No. de Usuarios</th>
                                <th className='titulo-reportes'></th>
                            </tr>
                        </thead>

                        <tbody>
                            <tr>
                                <td>10</td>
                                <td>Total</td>
                            </tr>
                        </tbody>
                        <th>
                        </th>
                    </table>
                </div>


                <div className='exportar-reportes'>
                    <i className="bi bi-file-earmark-pdf"></i>
                    <i className="bi bi-file-earmark-spreadsheet"></i>
                    <i className="bi bi-card-image"></i>
                </div>

            </div>


            </div>
        </div>
    )
}

export default ReportesUsuarios