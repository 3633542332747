import React from 'react'
import { Table } from 'react-super-responsive-table';



function HistoriaEstudios() {
  return (
    <div  className='historia-clinica'>

      
<div  className='subtitulo'>
      <h3>Historia Estudios</h3>
   </div>




        <div>
        <Table >
            <thead>

            <tr >
            <th className='color-table'> </th>
                    
                    <th className='color-table'><a type='button' >Añadir      <i className="bi bi-plus"></i></a></th>
                    <th className='color-table'> <a type='button'>Editar      <i className="bi bi-pencil-square"></i></a></th>
                    <th className='color-table'><a type='button'>Actualizar   <i className="bi bi-check-circle"></i></a></th>
    
   
                </tr>
                <tr >
                    <th > </th>
                    
                    <th className='color-table'> Estudio</th>
                    <th className='color-table'> Fecha</th>
                    <th className='color-table'> Descripción Detallada</th>

                </tr>
            </thead>
            <tbody>
                <tr>
                    <td className='border-td' width={"20px"}>
                        X
                    </td>


                    <td className='border-td'>
                        Urianálisis
                    </td>


                    <td className='border-td'>
                        12/12/20
                    </td>

                    <td className='border-td'>
                        Lorem Ipsum   text area
                    </td>



                </tr>
            </tbody>
        </Table>

        </div>



<div>

<div className='titulo'>
        <p >Exploración física:</p>
    </div>


                           <div className='div-direccion'>
                                <input className='input-direccion' placeholder='Estatura' />
                                <br />
                                <input className='input-direccion' placeholder='Peso (kg)' />
                                <br />
                                <input className='input-direccion' placeholder='Indice masa coorporal' />
                            </div>

                            <br/>
 <div className='titulo'>
        <p >Perímetro de tórax a nivel del apéndice xifoides</p>
    </div>



                           <div className='div-direccion'>
                                <input className='input-direccion' placeholder='Perímetro del abdomen a nivel ombligo (cms)' />
                                <br />
                                <input className='input-direccion' placeholder='En inspiración plena (cms)' />
                              
                            </div>

<br/>

                            <div className='div-direccion'>
                              
                                <input className='input-right' placeholder='En inspiración forzada(cms)' />
                              
                            </div>

</div>
        


    </div>
  )
}

export default HistoriaEstudios