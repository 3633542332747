import React from 'react'

function ConsultaHorario() {
  return (
    <div className='data-consulta'>
      <div className='subtitulo'>
        <h3>Asignación de Fecha y Hora de la consulta:</h3>
      </div>

      <div className='div-direccion'>
        <div className='relleno salud'>

          <p>Fecha:</p>
          <select name="select">
            <option value="value1">Seleccione una Opción</option>
          </select>
        </div>
        <br />
        <div className='relleno  salud'>
          <p>Hora:</p>
          <select name="select">
            <option value="value1">Seleccione una Opción</option>
          </select>
          <br />
          <br />
        </div>
      </div>
    </div>
  )
}

export default ConsultaHorario