import React from "react";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import "../css/MenuNuevo.css";
import { addClass } from "../Registrar/Funciones";
import AdministrarPersonal from "./AdministrarPersonal";
import AdministrarPacientes from "./AdministrarPacientes";
import AdministrarClinicas from "./AdministrarClinicas"


function  MenuAdministrarUsuarios() {

  return (
    <div id="container2">
      <div className="titulo-principal">
        <h2>Administrar Usuarios </h2>
      </div>

      <div className="menu-secundary">
        <nav>
            <ul>
                <li><button id="btn1"   className="boton-menu"  onClick={() => addClass("usuarios"      ) } >Personal    </button></li> 
                <li><button id="btn2"   className="boton-menu"  onClick={() => addClass("clientes"      ) }>Clinicas     </button></li>
                <li><button id="btn3"   className="boton-menu"  onClick={() => addClass("marcas"        ) }>Pacientes       </button></li>
            </ul>
        </nav>
      </div>

      <div className="contenido-dinamico">
             <div id="usuarios"  className="contenido-din" >
            <AdministrarPersonal/>
            </div>


            <div id="clientes"  className="contenido-din" >
            <AdministrarClinicas/>
            </div>


            <div id="marcas"  className="contenido-din" >
            <AdministrarPacientes/>
            </div>
    </div>
 
 


    </div>
  );
}

export default MenuAdministrarUsuarios;
