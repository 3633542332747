import React from "react";
import "./css/MenuContenido.css";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import MenuConsulta from "./Registrar/ConsultasExternas/MenuConsulta";
import MenuHistoria from "./Registrar/HistoriaClinica/MenuHistoria";
import DatosPaciente from "./Registrar/DatosPaciente";
import DatosConsulta from "./Registrar/ConsultasExternas/DatosConsulta";
import RegistrarEstudios from "./Registrar/EstudiosLaboratorio/RegistrarEstudios";
import  MenuConsultasAtenciones from "./Registrar/ConsultasAtenciones/MenuConsultasAtenciones"
import { addClass } from "../Usuarios/Registrar/Funciones";


function MenuContenidoConsultas() {

  return (
    <div id="container">
      <div className="titulo-principal">
        <h2>Registros Consultas</h2>
      </div>

      <div className="menu-secundary">
        <nav>
            <ul>
                <li><button id="btn1"   className="boton-menu"  onClick={() => addClass("usuarios"      ) } >Historia clínica   </button></li> 
                <li><button id="btn2"   className="boton-menu"  onClick={() => addClass("clientes"      ) }>Consultas externas    </button></li>
                <li><button id="btn3"   className="boton-menu"  onClick={() => addClass("marcas"        ) }>Consultas y atenciones    </button></li>
                <li><button id="btn4"   className="boton-menu"  onClick={() => addClass("colaboradores" ) }>Estudios laboratorio</button></li>
            </ul>
        </nav>
      </div>
            <div id="usuarios" >

               <div className="personal">
                    <DatosPaciente />
                    <div className="prueba derecha ">
                      <MenuHistoria />
                    </div>
                  </div>
          
            </div>


            <div id="clientes" >
               <div className="contenido-izquierdo">
                    <DatosPaciente />

                    {/*========================== Llamado al Componente ==========================*/}
                    <div className="prueba derecha ">
                      <MenuConsulta />
                    </div>
                  </div>
            </div>


            <div id="marcas" >

            <div className="personal">
                    {/*========================== Llamado al Componente ==========================*/}
                    <DatosPaciente />
                    <div className="prueba derecha ">
                      <MenuConsultasAtenciones />
                    </div>
                  </div>
       
            </div>

            <div  id="colaboradores">
            <div className="personal">
                    {/*========================== Llamado al Componente ==========================*/}

                    <DatosPaciente />

                    <div className="prueba derecha ">
                      <DatosConsulta />
                      <RegistrarEstudios />
                    </div>
                  </div>

            </div>

 


    </div>
  );
}

export default MenuContenidoConsultas;
