import React from "react";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import { addClass } from "../../Usuarios/Registrar/Funciones";
import ProgramasPrevencion from './ProgramasPrevencion';
import Comunicados from './AdmComunicados';

function AdministrarProgramas() {

  return (
    <div id="container2">

      <div>
      <div className="titulo-principal">
        <h2>Administrar Programas </h2>
      </div>

      <div className="menu-secundary">
        <nav>
            <ul>
                <li><button id="btn1"   className="boton-menu"  onClick={() => addClass("usuarios"      ) } >Programas de prevención    </button></li> 
                <li><button id="btn2"   className="boton-menu"  onClick={() => addClass("clientes"      ) }>Comunicados   </button></li>
            </ul>
        </nav>
      </div>
    </div>


           <div className="contenido-dinamico">
            <div id="usuarios" className="adm-comunicados"  >
             <ProgramasPrevencion />
            </div>
            <div id="clientes"  className="adm-comunicados">
            <Comunicados/>
            </div>
            
            </div>


    </div>
  );
}

export default  AdministrarProgramas;
