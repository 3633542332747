import React from 'react'
import { Table } from 'react-super-responsive-table';
import '../../css/Detecciones.css'
import { Icon } from '@iconify/react';





function Detecciones() {
    return (
        <div className='container-planificacionFam'>
            <div>
                <div className='subtitulo'>
                    <h3>Detecciones SINBA-SIS-DET-P</h3>
                </div>
                <div className='container-table-detecciones'>
                    <Table className='table-detecciones'>
                        <thead className='thead-detecciones' style={{ color: "#3639A4" }}>
                            <tr>
                                <th className='icon-detecciones'><Icon icon="fluent:book-search-20-regular" className='bi' /></th>
                                <th className='icon-detecciones'><Icon icon="ph:plus-minus-bold" width="30" /></th>

                            </tr>
                        </thead>
                        <tbody className='tbody-detecciones' style={{ background: "#3639A4 !important" }}>
                            <tr>
                                <td className='row-detecciones'>Diabetes mellitus</td>
                                <td><button className='button-detecciones'>Positivo</button></td>

                            </tr>
                            <tr>
                                <td className='row-detecciones'>Hipertensión arterial</td>
                                <td><button className='button-detecciones'>Positivo</button></td>

                            </tr>
                            <tr>
                                <td className='row-detecciones'>Obesidad</td>
                                <td><button className='button-detecciones'>Positivo</button></td>
                            </tr>
                            <tr>
                                <td className='row-detecciones'>Dislipidemias</td>
                                <td><button className='button-detecciones'>Positivo</button></td>
                            </tr>
                            <tr>
                                <td className='row-detecciones'>Depresión</td>
                                <td><button className='button-detecciones'>Positivo</button></td>
                            </tr>
                            <tr>
                                <td className='row-detecciones'>Alteración de memoria</td>
                                <td><button className='button-detecciones'>Positivo</button></td>
                            </tr>
                            <tr>
                                <td className='row-detecciones'>Sintomático respiratorio</td>
                                <td><button className='button-detecciones'>Positivo</button></td>
                            </tr>
                            <tr>
                                <td className='row-detecciones'>Alcoholismo (Adicción)</td>
                                <td><button className='button-detecciones'>Positivo</button></td>
                            </tr>
                            <tr>
                                <td className='row-detecciones'>Tabaquismo (Adicción)</td>
                                <td><button className='button-detecciones'>Positivo</button></td>
                            </tr>
                            <tr>
                                <td className='row-detecciones'>Fármacos (Adicción)</td>
                                <td><button className='button-detecciones'>Positivo</button></td>
                            </tr>
                            <tr>
                                <td className='row-detecciones'>Incontinencia urinaria (+60 años)</td>
                                <td><button className='button-detecciones'>Positivo</button></td>
                            </tr>
                            <tr>
                                <td className='row-detecciones'>Síndrome de caídas (+60 años)</td>
                                <td><button className='button-detecciones'>Positivo</button></td>
                            </tr>
                            <tr>
                                <td className='row-detecciones'>Riesgo de fracturas por osteoporosis (+50 años)</td>
                                <td><button className='button-detecciones'>Positivo</button></td>
                            </tr>
                            <tr>
                                <td className='row-detecciones'>Gonorrea</td>
                                <td><button className='button-detecciones'>Positivo</button></td>
                            </tr>
                            <tr>
                                <td className='row-detecciones'>VIH</td>
                                <td><button className='button-detecciones'>Positivo</button></td>
                            </tr>
                            <tr>
                                <td className='row-detecciones'>ITS secretoras</td>
                                <td><button className='button-detecciones'>Positivo</button></td>
                            </tr>
                            <tr>
                                <td className='row-detecciones'>ITS ulcerativas</td>
                                <td><button className='button-detecciones'>Positivo</button></td>
                            </tr>
                            <tr>
                                <td className='row-detecciones'>ITS tumorales</td>
                                <td><button className='button-detecciones'>Positivo</button></td>
                            </tr>
                            <tr>
                                <td className='row-detecciones'>Sífilis</td>
                                <td><button className='button-detecciones'>Positivo</button></td>
                            </tr>
                            <tr>
                                <td className='row-detecciones'>Sospecha de síndrome de turneR</td>
                                <td><button className='button-detecciones'>Positivo</button></td>
                            </tr>
                            <tr>
                                <td className='row-detecciones'>Violencia familiar (+15 años)</td>
                                <td><button className='button-detecciones'>Positivo</button></td>
                            </tr>
                            <tr>
                                <td className='row-detecciones'>Hiperplasia prostática en hombres (+45 años)</td>
                                <td><button className='button-detecciones'>Positivo</button></td>
                            </tr>
                        </tbody>
                    </Table>
                </div>





                <div className='subtitulo'>
                    <h3>Insumos utilizados</h3>
                </div>


                <div className='container-izq-der'>

                    <div className='izquierdo'>

                        <div className='relleno salud'>
                            <p>Tiras embarazadas sanas :</p>
                            <input className='input-direccion' placeholder='' />
                        </div>
                        <div className='relleno salud'>
                            <p>Tiras control de pacientes </p>
                            <input className='input-direccion' placeholder='' />
                        </div>



                    </div>



                    <div className='derecho'>




                        <div className='relleno salud'>
                            <p>Tiras detección :</p>
                            <input className='input-direccion' placeholder='' />
                        </div>

                        <div className='relleno salud'>
                            <p>Reactivos de antígeno prostático :</p>
                            <input className='input-direccion' placeholder='' />
                        </div>





                    </div>
                </div>
                <div className='registrar'>
                    <br />
                    <button type="button" className="btn btn-primary registrar">GUARDAR</button>


                    <br />
                    <br />

                </div>




            </div>
        </div>



    )
}
export default Detecciones;