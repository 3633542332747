import React from 'react'

function DatosGenerales() {
  return (
    <div className='nota-medica'>
      <div className='subtitulo'>
        <h3>Datos Generales</h3>
      </div>
      <div className='div-direccion'>
        <div className='relleno salud'>

          <p>Hora del Evento:</p>
          <select name="select">
            <option value="value1">Seleccione una Opción</option>
          </select>
        </div>
        <br />
        <div className='relleno  salud'>

          <p> Sitio del Evento:</p>
          <input className='input-direccion' placeholder='Ingrese sitio del evento' />
          <br />
          <br />
        </div>
      </div>

      <div className='div-direccion'>
        <div className='relleno salud'>

          <p>Hora de egreso:</p>
          <select name="select">
            <option value="value1">Seleccione una Opción</option>
          </select>
        </div>
        <br />
        <div className='relleno  salud'>
          <p>Ambulancia:</p>
          <select name="select">
            <option value="value1">Seleccione una Opción</option>
          </select>
          <br />
          <br />
        </div>
      </div>
      <div className='div-direccion'>
        <div className='relleno salud'>

          <p>Codigo:</p>
          <select name="select">
            <option value="value1">Seleccione una Opción</option>
          </select>
        </div>
        <br />
        <div className='relleno  salud'>

          <p>Prioridad:</p>
          <select name="select">
            <option value="value1">Seleccione una Opción</option>
          </select>
          <br />
          <br />
        </div>
      </div>


      <div className='div-direccion'>
        <div className='relleno salud'>
          <p>Entidad Federativa de procedencia:</p>
          <select name="select">
            <option value="value1">Seleccione una Opción</option>
          </select>
        </div>
        <br />
        <div className='relleno  salud'>
          <p>Lugar de Procedencia:</p>
          <select name="select">
            <option value="value1">Seleccione una Opción</option>
          </select>
          <br />
          <br />
        </div>
      </div>
      <div className='div-direccion'>
        <div className='relleno salud'>
          <p>Aviso Previo:</p>
          <select name="select">
            <option value="value1">Seleccione una Opción</option>
          </select>
        </div>
        <br />
        <div className='relleno  salud'>

          <p> Medio</p>
          <input className='input-direccion' placeholder='Ingrese Medio' />
          <br />
          <br />
        </div>
      </div>
      <div className='div-direccion'>
        <div className='relleno salud'>
          <p>Responsable:</p>
          <input className='input-direccion' placeholder='Ingrese Responsable' />
        </div>
        <br />
        <div className='relleno  salud'>
          <p>Parentesco:</p>
          <input className='input-direccion' placeholder='Ingrese Parentesco' />
          <br />
          <br />
        </div>
      </div>

      <div className='enviar'>
                <button type="button" className="btn btn-primary registrar">GUARDAR</button>
                <br />
                <br />
            </div>


    </div>
  )
}

export default DatosGenerales