import React, { PureComponent } from 'react';
import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ReferenceLine,
  ResponsiveContainer,
} from 'recharts';

function ReportesUrgencias() {
    const data = [
        {
          name: 'Page A',
          uv: 4000,
          pv: 2400,
          amt: 2400,
        },
        {
          name: 'Page B',
          uv: -3000,
          pv: 1398,
          amt: 2210,
        },
        {
          name: 'Page C',
          uv: -2000,
          pv: -9800,
          amt: 2290,
        },
        {
          name: 'Page D',
          uv: 2780,
          pv: 3908,
          amt: 2000,
        },
        {
          name: 'Page E',
          uv: -1890,
          pv: 4800,
          amt: 2181,
        },
        {
          name: 'Page F',
          uv: 2390,
          pv: -3800,
          amt: 2500,
        },
        {
          name: 'Page G',
          uv: 3490,
          pv: 4300,
          amt: 2100,
        },
      ];
    return (
        <div className='reportes'>
            <h2> Reportes  de Urgencias</h2>

            <div className='info-graficas'>
                <div className='reportes-seleccion'>
                    <div className='tipo-usuario'>
                        <select className='reportes' >
                            <option disabled selected> Tipo de Usuario</option>
                            <option>Admin</option>
                        </select>
                        <select className='reportes' >
                            <option disabled selected>  Tipo de Reporte</option>
                            <option>Admin</option>
                        </select>
                    </div>
                    <div className='fecha-usuario'>
                        <select className='reportes' >
                            <option disabled selected> Seleccionar Fecha de Inicio</option>
                            <option>-----</option>
                        </select>
                    </div>

                    <div className='fecha-usuario-final'>
                        <select className='reportes' >
                            <option disabled selected>   Seleccionar Fecha de Fin</option>
                            <option>-----</option>
                        </select>
                    </div>

                </div>
            </div>



            <div className='grafica'>
                <div className='grafica1'>
                
        <BarChart
          width={500}
          height={300}
          data={data}
          stackOffset="sign"
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          <Legend />
          <ReferenceLine y={0} stroke="#000" />
          <Bar dataKey="pv" fill="#8884d8" stackId="stack" />
          <Bar dataKey="uv" fill="#82ca9d" stackId="stack" />
        </BarChart>

                </div>
                <div className='grafica2'>
                <BarChart
          width={500}
          height={300}
          data={data}
          stackOffset="sign"
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          <Legend />
          <ReferenceLine y={0} stroke="#000" />
          <Bar dataKey="pv" fill="#8884d8" stackId="stack" />
          <Bar dataKey="uv" fill="#82ca9d" stackId="stack" />
        </BarChart>

                </div>

            </div>




   






            <div className='informacion-graficas'>
                <div className='tabla-reportes'>
                    <table>
                        <thead>
                            <tr>
                                <th className='titulo-reportes'>No. de Usuarios</th>
                                <th className='titulo-reportes'></th>
                            </tr>
                        </thead>

                        <tbody>
                            <tr>
                                <td>10</td>
                                <td>Total</td>
                            </tr>
                        </tbody>
                        <th>
                        </th>
                    </table>
                </div>


                <div className='exportar-reportes'>
                    <i className="bi bi-file-earmark-pdf"></i>
                    <i className="bi bi-file-earmark-spreadsheet"></i>
                    <i className="bi bi-card-image"></i>
                </div>

            </div>


        </div>
    )
}

export default ReportesUrgencias