import React from 'react'
import { Table } from 'react-super-responsive-table';


function Pulso() {
  return (
    <div  className='historia-clinica'>

<div  className='subtitulo'>
      <h3>Pulso</h3>
   </div>


<div>

<div className='titulo'>
        <p>*En caso de extras´sitoles practicar la prueba de esfuerzo al margen 3A. El solicitante practicará el ejercicio
            físico(sentadillas,carrera estacionaría, etc.) de modo que su frecuencía se incremente
            al 100/min. minimo, registrado en el pulso como se solicita.
 

        </p>
    </div>

        <Table >
            <thead>
            <tr >
            <th className='color-table'> </th>
                    
                    <th className='color-table'><a type='button' >Añadir      <i className="bi bi-plus"></i></a></th>
                    <th className='color-table'> <a type='button'>Editar      <i className="bi bi-pencil-square"></i></a></th>
                    <th className='color-table'><a type='button'>Actualizar   <i className="bi bi-check-circle"></i></a></th>
    
   
                </tr>
                <tr >
                    <th >  </th>
                    <th className='color-table'> Tipo de esfuerzo</th>
                    <th className='color-table'> ¿Es rítmico?</th>
                    <th className='color-table'> Número de extrasístoles por miuto</th>

                </tr>
            </thead>
            <tbody>
                <tr>
                    <td className='border-td'>
                        x
                    </td>


                    <td className='border-td'>
                        En reposo
                    </td>


                    <td className='border-td'>
                        Si
                    </td>

                    <td className='border-td'>
                        5
                    </td>



                </tr>
            </tbody>
        </Table>

        </div>





        <div>


<br/>
<div className='titulo'>
        <p >Presión arterial (método ausculatorio):</p>
        <p>*En caso de encontrar cifras superiores a los 140/90 hacer dos lecturas adicionales a margen 4A
        </p>
    </div>

        <Table >
            <thead>

            <tr >
            <th className='color-table'> </th>
                    
                    <th className='color-table'><a type='button' >Añadir      <i className="bi bi-plus"></i></a></th>
                    <th className='color-table'> <a type='button'>Editar      <i className="bi bi-pencil-square"></i></a></th>
                    <th className='color-table'><a type='button'>Actualizar   <i className="bi bi-check-circle"></i></a></th>
    
   
                </tr>
                
                <tr >
                    <th >  </th>
                    <th className='color-table'>No. de lectura</th>
                    <th className='color-table'> Sistólica</th>
                    <th className='color-table'> Diastólica</th>

                </tr>
            </thead>
            <tbody>
                <tr>
                    <td className='border-td'>
                      X
                    </td>


                    <td className='border-td'>
                      1
                    </td>


                    <td className='border-td'>
                      169
                    </td>

                    <td className='border-td'>
                      45
                    </td>
                </tr>
            </tbody>
        </Table>

        </div>








        


        <div>


<br/>
<div className='titulo'>
        <p >Región precordial:</p>
        <p>*En caso de cualquier antecedente o hallazgo cardiovascular, practicar el examén cardiovascular al margen 5A.
        </p>
    </div>

        <Table >
            <thead>
            <tr >
            <th className='color-table'> </th>
                    
                    <th className='color-table'><a type='button' >Añadir      <i className="bi bi-plus"></i></a></th>
                    <th className='color-table'> <a type='button'>Editar      <i className="bi bi-pencil-square"></i></a></th>
                    <th className='color-table'><a type='button'>Actualizar   <i className="bi bi-check-circle"></i></a></th>
    
   
                </tr>
                
                
                <tr >
                    <th > </th>
                    <th className='color-table'>Preguntas</th>
                    <th className='color-table'> Respuestas</th>
                    <th className='color-table'> </th>
                    <th> </th>

                </tr>
            </thead>
            <tbody>
                <tr>
                    <td className='border-td'>
                      X
                    </td>


                    <td className='border-td'>
                       ¿Existe algún dato anormal a la palpación?
                    </td>


                    <td className='border-td'>
                        Sí
                    </td>

                    <td className='border-td'>
                       
                    </td>
                </tr>
            </tbody>
        </Table>

        <button className='btn buton-registrar'>
                            Realizar
                             examén cardiovascular 
                        </button>

        </div>























    </div>
  )
}

export default Pulso