import React from 'react'
import { Table } from 'react-super-responsive-table';


function Odontograma() {
    return (


        <div className='historia-clinica'>
            <div className='subtitulo'>
                <h3>Odontograma</h3>
            </div>
            <div className='registrar'>
                <button type="button" className="btn btn-primary registrar">
                    Solicitar Odontograma</button>
            </div>
            <div >
                <div className='titulo'>
                    <p >Resumen:</p>
                    <p>*Ante cualquier respuesta afirmativa, dar información detallada</p>
                </div>

                <Table >
                    <thead>

                        <tr >
                            <th className='color-table'> </th>
                            <th className='color-table'><a type='button' >Añadir      <i className="bi bi-plus"></i></a></th>
                            <th className='color-table'> <a type='button'>Editar      <i className="bi bi-pencil-square"></i></a></th>
                            <th className='color-table'><a type='button'>Actualizar   <i className="bi bi-check-circle"></i></a></th>
                        </tr>
                        <tr >
                            <th >  </th>
                            <th className='color-table'> Preguntas</th>
                            <th className='color-table'> Respuestas</th>
                            <th className='color-table'> Detalles</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td className='border-td'>
                                X
                            </td>
                            <td className='border-td'>
                                ¿Alguna anormalidad en su aspecto?
                            </td>
                            <td className='border-td'>
                                Sí
                            </td>
                            <td className='border-td'>
                                Lorem Ipsum dol text
                            </td>

                        </tr>
                    </tbody>
                </Table>
            </div>
            <div>
                <br />
                <Table >
                    <thead>
                        <tr >
                            <th className='color-table'> Fecha</th>
                            <th  >  </th>
                            <th className='color-table' > Consultorio</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td className='border-td'>
                                <p> 15/06/2021</p>
                            </td>
                            <td width={"600px"}>

                            </td>
                            <td className='border-td'>
                                <p>--select</p>
                            </td>
                        </tr>
                    </tbody>
                </Table>
            </div>
            <div >
                <div className='subtitulo'>
                    <h3>Médico responsable</h3>
                </div>
                <Table >
                    <thead>
                        <tr >
                            <th className='color-table'> Médico </th>
                            <th className='color-table'>Cédula profesional</th>
                            <th className='color-table'> Firma</th>

                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td className='border-td'>
                                <p>Daniela Martínez  Martínez</p>
                            </td>


                            <td className='border-td'>
                                <p>351654156462</p>
                            </td>

                            <td className='border-td'>
                                <p>--seleccione archivo</p>
                            </td>

                        </tr>
                    </tbody>
                </Table>

            </div>







        </div>
    )
}

export default Odontograma