import React from 'react'
import { useState } from 'react';
import { Table } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import "../css/menuConsultas.css";
import AdministrarHistoriasClinicas from "./AdministrarHistoriasClinicas";
import AdministrarConsultasExternas from "./AdministrarConsultasExternas";
import AdministrarConsultasAtenciones from "./AdministrarConsultasAtenciones";
import AdministrarEstudiosLaboratorio from "./AdministrarEstudiosLaboratorio";
import { addClass } from '../../Usuarios/Registrar/Funciones';

function AdministrarConsultas() {


  return (
    <div id="container2">
    <div className="titulo-principal">
      <h2>Administrar Consultas </h2>
    </div>

    <div className="menu-secundary">
      <nav>
          <ul>
              <li><button id="btn1"   className="boton-menu"  onClick={() => addClass("usuarios"      ) } >Historias clínicas  </button></li> 
              <li><button id="btn2"   className="boton-menu"  onClick={() => addClass("clientes"      ) }>Consultas externas   </button></li>
              <li><button id="btn3"   className="boton-menu"  onClick={() => addClass("marcas"        ) }>Consultas y atenciones       </button></li>
              <li><button id="btn4"   className="boton-menu"  onClick={() => addClass("colaboradores" ) }>Estudios y laboratorio</button></li>

          </ul>
      </nav>
    </div>


<div  id='contenido-dinamico'>



           <div id="usuarios"  className="contenido-din" >
         <AdministrarHistoriasClinicas />
          </div>


          <div id="clientes" className="contenido-din" >
    < AdministrarConsultasExternas />
          </div>


          <div id="marcas"  className="contenido-din">
            <AdministrarConsultasAtenciones/>
          </div>

          <div  id="colaboradores" className="contenido-din">
            <AdministrarEstudiosLaboratorio />
          </div>

          </div>


  </div>
  )
}

export default AdministrarConsultas