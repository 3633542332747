import React from 'react'
import "../../css/laboratorio.css";

function RegistrarEstudios() {
  return (
    <div  className='estudios'>

      <div className='div-direccion '>
        <div className='relleno estudios'>

          <p>No. Registro:</p>
          <input className='input-direccion' placeholder='' />

        </div>
        <br />

        <div className='relleno  completo estudios'>

          <p> Laboratorio:</p>
          <select name="select">
            <option value="value1">Seleccione una Opción</option>
          </select>

        </div>

        <br />
      </div>





      <div className='div-direccion'>
        <div className='relleno estudios'>

          <p>Fecha:</p>
          <select name="select">
            <option value="value1">Seleccione una Opción</option>
          </select>

        </div>
        <br />

        <div className='relleno  completo estudios'>

          <p> Estudio:</p>
          <select name="select">
            <option value="value1">Seleccione una Opción</option>
          </select>

        </div>

        <br />
      </div>


      <div className='div-direccion'>
        <div className='relleno  estudios'>

          <p>Hora:</p>
          <select name="select">
            <option value="value1">Seleccione una Opción</option>
          </select>
        </div>
        <br />

        <div className='relleno  completo estudios'>

          <p> Firma (Médico):</p>
          <input type="file" className='input-direccion' placeholder='' />

        </div>

        <br />
      </div>


      <div className='enviar'>
        <button type="button" className="btn btn-primary registrar">Enviar Solicitud</button>


        <br />
        <br />

      </div>


    </div>
  )
}

export default RegistrarEstudios