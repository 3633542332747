let menu = ["usuarios", "clientes","marcas", "colaboradores", "proveedores"];
export const rows = [];


export function addClassActive() {
    var element = document.getElementById("container");
    element.classList.toggle("active");
    var element = document.getElementById("menulateral");
    element.classList.toggle("active");
}
export function addClass(name) {
  for (let index = 0; index < menu.length - 1; index++) {
    if( name === menu[index] ){
      var element= document.getElementById(name);
      element.classList.add("active");
    }else{
      var element2 = document.getElementById( menu[index] );
      element2.classList.remove("active");   
    }  
  }
}


  