import React from 'react'

function Diagnostico() {
  return (
    <div className='nota-medica'>
      <div className='subtitulo'>
        <h3>Diagnóstico</h3>
      </div>
      <div className='div-direccion'>
        <div className='relleno salud'>

          <p>CIE -10:</p>
          <select name="select">
            <option value="value1">Seleccione una Opción</option>
          </select>
        </div>
        <br />
        <div className='relleno  salud'>

          <p>Tratamiento prehospitalario:</p>
          <select name="select">
            <option value="value1">Seleccione una Opción</option>
          </select>
          <br />
          <br />
        </div>
      </div>


      <div className='div-direccion'>
        <div className='relleno salud'>

          <p>Motivo de la consulta:</p>
          <select name="select">
            <option value="value1">Seleccione una Opción</option>
          </select>
        </div>
        <br />
        <div className='relleno  salud'>

          <p>Interrogatorio:</p>
          <select name="select">
            <option value="value1">Seleccione una Opción</option>
          </select>
          <br />
          <br />
        </div>
      </div>




      <div className='div-direccion'>
        <div className='relleno salud'>

          <p>Diagnóstico principal:</p>
          <textarea name="textarea" rows="8" cols="30"></textarea>
        </div>
        <br />
        <div className='relleno  salud'>

          <p> Diagnóstico de envio:</p>

          <textarea name="textarea" rows="8" cols="30"></textarea>

          <br />
          <br />
        </div>
      </div>






      <div className='relleno  salud'>

        <p>Resumen de Interrogatorio, exploracion física y/o estado mental:</p>
        <textarea name="textarea" rows="8" cols="30"></textarea>
      </div>
      <br />
      <div className='relleno  salud'>

        <p>Diagnóstico o problemas clínicos:</p>

        <textarea name="textarea" rows="8" cols="30"></textarea>

        <br />
        <br />
      </div>





      <div className='relleno  salud'>

        <p>Resultados de estudios auxiliares:</p>
        <textarea name="textarea" rows="8" cols="30"></textarea>
      </div>
      <br />
      <div className='relleno  salud'>

        <p>Tratamiento:</p>

        <textarea name="textarea" rows="8" cols="30"></textarea>

        <br />

      </div>



      <div className='relleno  salud'>

        <p>Pronostico:</p>
        <textarea name="textarea" rows="8" cols="30"></textarea>
      </div>
      <br />



      <div className='enviar'>
                <button type="button" className="btn btn-primary registrar">GUARDAR</button>
                <br />
                <br />
            </div>






    </div>
  )
}

export default Diagnostico