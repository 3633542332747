import React, { PureComponent } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';

import "./Reportes.css";


function ReportesUsuarios() {
    const data = [
        {
            name: 'Page A',
            uv: 4000,
            pv: 2400,
            amt: 2400,
        },
        {
            name: 'Page B',
            uv: 3000,
            pv: 1398,
            amt: 2210,
        },
        {
            name: 'Page C',
            uv: 2000,
            pv: 9800,
            amt: 2290,
        },
        {
            name: 'Page D',
            uv: 2780,
            pv: 3908,
            amt: 2000,
        },
        {
            name: 'Page E',
            uv: 1890,
            pv: 4800,
            amt: 2181,
        },
        {
            name: 'Page F',
            uv: 2390,
            pv: 3800,
            amt: 2500,
        },
        {
            name: 'Page G',
            uv: 3490,
            pv: 4300,
            amt: 2100,
        },
    ];

    return (
        <div className='reportes'>
            <h2> Reportes  de Usuarios</h2>

            <div className='info-graficas'>
                <div className='reportes-seleccion'>
                    <div className='tipo-usuario'>
                        <select className='reportes' >
                            <option disabled selected> Tipo de Usuario</option>
                            <option>Admin</option>
                        </select>
                        <select className='reportes' >
                            <option disabled selected>  Tipo de Reporte</option>
                            <option>Admin</option>
                        </select>
                    </div>
                    <div className='fecha-usuario'>
                        <select className='reportes' >
                            <option disabled selected> Seleccionar Fecha de Inicio</option>
                            <option>-----</option>
                        </select>
                    </div>

                    <div className='fecha-usuario-final'>
                        <select className='reportes' >
                            <option disabled selected>   Seleccionar Fecha de Fin</option>
                            <option>-----</option>
                        </select>
                    </div>

                </div>
            </div>



            <div className='grafica'>
                <div className='grafica1'>
                    <LineChart width={500} height={300} data={data}>
                        <XAxis dataKey="name" />
                        <YAxis />
                        <CartesianGrid stroke="#eee" strokeDasharray="5 5" />
                        <Line type="monotone" dataKey="uv" stroke="#8884d8" />
                        <Line type="monotone" dataKey="pv" stroke="#82ca9d" />
                    </LineChart>
                </div>
                <div className='grafica2'>
                    <LineChart width={500} height={300} data={data}>
                        <XAxis dataKey="name" />
                        <YAxis />
                        <CartesianGrid stroke="#eee" strokeDasharray="5 5" />
                        <Line type="monotone" dataKey="uv" stroke="#8884d8" />
                        <Line type="monotone" dataKey="pv" stroke="#82ca9d" />
                    </LineChart>
                </div>

            </div>



            <div className='informacion-graficas'>
                <div className='tabla-reportes'>
                    <table>
                        <thead>
                            <tr>
                                <th className='titulo-reportes'>No. de Usuarios</th>
                                <th className='titulo-reportes'></th>
                            </tr>
                        </thead>

                        <tbody>
                            <tr>
                                <td>10</td>
                                <td>Total</td>
                            </tr>
                        </tbody>
                        <th>
                        </th>
                    </table>
                </div>


                <div className='exportar-reportes'>
                    <i className="bi bi-file-earmark-pdf"></i>
                    <i className="bi bi-file-earmark-spreadsheet"></i>
                    <i className="bi bi-card-image"></i>
                </div>

            </div>


        </div>
    )
}

export default ReportesUsuarios