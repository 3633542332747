import React from 'react'

function SaludNino() {
   return (
      <div className='data-consulta'>
         <div className='subtitulo'>
            <h3>Salud del niño</h3>
         </div>
         <div className='caja-completa'>
            <div className='div-direccion'>
               <div className='relleno salud'>
                  <p>Niño sano:</p>
                  <select name="select">
                     <option value="value1">Seleccione una Opción</option>
                  </select>
               </div>
               <br />
               <div className='relleno  salud'>
                  <p>Resultados batelle 16 meses a 4 años:</p>
                  <select name="select">
                     <option value="value1">Seleccione una Opción</option>
                  </select>
                  <br />
                  <br />
               </div>
            </div>
            <div className='div-direccion'>
               <div className='relleno salud'>
                  <p>Peso para la talla  &lt; 5 años:</p>
                  <select name="select">
                     <option value="value1">Seleccione una Opción</option>
                  </select>
               </div>
               <br />
               <div className='relleno  salud'>
                  <p>Aplicación cédula cancer &lt; 20 años</p>
                  <select name="select">
                     <option value="value1">Seleccione una Opción</option>
                  </select>
                  <br />
                  <br />
               </div>
            </div>



            <div className='izquierdo'>
               <div className='subtitulo'>
                  <h3>Prueba EDI   &lt; 5 años</h3>
               </div>
               <div className='relleno salud'>
                  <p>Tipo:</p>
                  <select name="select">
                     <option value="value1">Seleccione una Opción</option>
                  </select>
               </div>

               <div className='relleno salud'>
                  <p>Resultado:</p>
                  <select name="select">
                     <option value="value1">Seleccione una Opción</option>
                  </select>
               </div>
            </div>



            <div className='derecho'>
               <div className='subtitulo'>
                  <h3>Edad's</h3>
               </div>
               <div className='relleno salud'>
                  <p>Plan de Entrenamiento:</p>
                  <select name="select">
                     <option value="value1">Seleccione una Opción</option>
                  </select>
               </div>
               <div className='relleno salud'>
                  <p>Recuperado:</p>
                  <input className='input-direccion' placeholder='' />
               </div>
               <div className='relleno salud'>
                  <p>Numero de sobre VSO tratamiento:</p>
                  <input className='input-direccion' placeholder='' />
               </div>

               <div className='relleno salud'>
                  <p>Número de sobre VSO promoción:</p>
                  <input className='input-direccion' placeholder='' />
               </div>
            </div>
         </div>
      </div>
   )
}

export default SaludNino