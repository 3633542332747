import React from 'react'

function DatosAdicionales() {
  return (
    <div>
      <div className='subtitulo'>
        <h2>Nota Médica Inicial</h2>
      </div>



      <div className='div-direccion'>
        <div className='relleno'>
          <span>
            <p>Clave</p>
            <input   id="box"className='input-direccion' placeholder='' />
          </span>
        </div>
        <br />

        <div className='relleno  completo'>
          <span>
            <p> NHC</p>
            <input   id="box" className='input-direccion' placeholder='' />
          </span>
        </div>

        <br />
      </div>
      <br />


      <div className='div-direccion'>
        <div className='relleno'>
          <span>
            <p>Hora de registro</p>
            <input   id="box"className='input-direccion' placeholder='' />
          </span>
        </div>
        <br />
        <div className='relleno'>
          <span>
            <p>Folio de gratuidad</p>
            <input   id="box"className='input-direccion' placeholder='' />
          </span>
        </div>

     
        <br />
        <div className='relleno'>
          <span>
            <p>Folio</p>
            <input   id="box"className='input-direccion' placeholder='' />
          </span>
        </div>
      </div>
      <br />








  












    </div>
  )
}

export default DatosAdicionales