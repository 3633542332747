import React from 'react'

function PromocionSalud() {
  return (
    <div className='data-consulta'>
      <div className='subtitulo'>
        <h3>Promoción de la Salud</h3>
      </div>
      <div className='izquierdo'>
        <div className='relleno salud'>
          <p>Consulta integrada línea de vida:</p>
          <input className='input-direccion' placeholder='' />
        </div>

        <div className='relleno salud'>
          <p>Referido por:</p>
          <select name="select">
            <option value="value1">Seleccione una Opción</option>
          </select>
        </div>
      </div>
      <div className='derecho'>
        <div className='relleno salud'>
          <p>Presenta cartilla:</p>
          <input className='input-direccion' placeholder='' />
        </div>

        <div className='relleno salud'>
          <p>Contrareferido:</p>
          <input className='input-direccion' placeholder='' />
        </div>
        <div className='relleno salud'>
          <p>En control y seguimiento:</p>
          <input className='input-direccion' placeholder='' />
        </div>
      </div>
    </div>
  )
}

export default PromocionSalud